import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import PageHeading from './../components/page-heading'
import Container from './../components/container'

export default props => {
  const {data} = props
  const prismicData = {
    pageHeading: data.prismicPaypalSuccessPage.data.page_heading[0].text,
    bodyCopy: data.prismicPaypalSuccessPage.data.body_copy[0].text,
  }

  return (
    <Layout pageName="paypal-success" documentTitlePrefix={prismicData.pageHeading}>
      <PageHeading heading={prismicData.pageHeading} />
      <Container>
        <Fade>
          <StyledPaypalSuccessMessage>
            <p>{prismicData.bodyCopy}</p>
          </StyledPaypalSuccessMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicPaypalSuccessPage {
      data {
        page_heading {
          text
        }
        body_copy {
          text
        }
      }
    }
  }
`

const StyledPaypalSuccessMessage = styled.div`
  font-weight: 100;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16rem;
  max-width: 52rem;
  text-align: center;

  p {
    margin-bottom: 1rem;
  }
`
